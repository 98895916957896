@import "../../../sass/variables.scss";
.footer {
  width: 100%;
  max-height: 300px;
  line-height: 60px;
  background-color: $light;
  color: $black;
  margin-top: 1.5rem;
  &__wingu {
    width: 150px;
    height: auto;
    margin: 1rem;
    path {
      fill: $black;
    }
  }
  &__civic_house {
    width: 120px;
    height: auto;
    fill: $primary;
    color: black;
    margin: 1rem;
    path {
      fill: $black;
    }
  }

  &__fonselp {
    margin: 1rem;
    width: 150px;
    height: auto;
    fill: $primary;
    color: black;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.container_title {
  margin-top: 25px;
}
