@import "./sass/variables.scss";
@import "./sass/vendor.scss";

#id {
  width: 100%;
}

.card-columns {
  margin: 1.5rem 0 6rem 0;
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }

  @include media-breakpoint-only(md) {
    column-count: 2;
  }
}
body {
  position: relative;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}
