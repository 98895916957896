@import "../../../sass/variables.scss";
.socialNetworks {
  display: inline-block;
  padding: 8px 5px;
  cursor: pointer;
  font-size: 15px;
  color: #000;
  &:hover {
    color: $primary;
  }
}
